import { useState } from 'react'
import { Button, Upload, Input } from 'antd'
import PageLayout from '../../layouts/PageLayout'
import { UploadOutlined } from '@ant-design/icons'
import { ExcelToJSON } from '../../utils/excel'
import { columns } from './columns'
import { client } from '../../utils/client'
import TableList from '../../components/TableList'

const List = () => {
  const [payload, setPayload] = useState()

  const fetch = async ({ page, pageSize }) => {
    const result = await client
      .get('/salesman', {
        params: {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          order: { from: 'ASC' },
        },
      })
      .then((res) => res.data)
    return result
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const xl2json = new ExcelToJSON()
      const data = await xl2json.parseExcel(file)
      const result = await client.post('/salesman', {
        data: data,
      })
      setPayload(data)
    } catch (err) {
      console.log('Error: ', err)
      const error = new Error('Some error')
      onError({ err })
    }
  }

  return (
    <PageLayout
      title="จัดการเซลล์"
      extra={[
        <div key="extra" style={{ display: 'flex' }}>
          <Upload accept=".xlsx" customRequest={customRequest} showUploadList={false}>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              style={{ backgroundColor: '#B9A467', border: 'none' }}
            >
              อัพโหลดไฟล์
            </Button>
          </Upload>
        </div>,
      ]}
    >
      {/* <Input.Search placeholder="Search by..." enterButton onSearch={search} /> */}
      <TableList key="saleId" fetch={fetch} columns={columns} />
    </PageLayout>
  )
}

export default List
