import dayjs from 'dayjs'
import { Select, message } from 'antd'
import { client } from '../../utils/client'

const { Option } = Select

const options = [
  { label: 'ยังไม่ติดต่อ ', value: 'NOT_CONTACTED' },
  { label: 'ติดต่อแล้ว', value: 'CONTACTED' },
]

const handleChange = async (v, id) => {
  try {
    await client.patch(`meeting/${id}`, { status: v })
    message.success('success')
  } catch (e) {
    console.log(e)
    message.error('fail')
  }
}

export const columns = [
  {
    title: 'รหัสผู้แทน',
    dataIndex: 'saleId',
    key: 'saleId',
  },
  {
    title: 'หมอ',
    render: function doctorName(record) {
      return (
        <p>
          {record.doctorFirstname} {record.doctorLastname}
        </p>
      )
    },
  },
  {
    title: 'ชื่อไลน์',
    dataIndex: 'doctorLinename',
    key: 'doctorLinename',
  },
  {
    title: 'โทร',
    dataIndex: 'telNo',
    key: 'telNo',
  },
  {
    title: 'หัวข้อ',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'เวลานัด',
    dataIndex: 'meetingTime',
    key: 'meetingTime',
  },
  {
    title: 'วันที่กด',
    render: function showDate(record) {
      return <p>{dayjs(record.createdAt).format('DD MMM YYYY')}</p>
    },
  },
  {
    title: 'สถานะ',
    width: 10,
    render: function showDate(record) {
      return (
        <Select
          defaultValue={record.status}
          style={{ width: '100%' }}
          onChange={(v) => handleChange(v, record._id)}
        >
          {options.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
  },
]
