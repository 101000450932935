import React, { useState } from 'react'
import PageLayout from '../../layouts/PageLayout'
import { client } from '../../utils/client'
import { Select, Input, DatePicker, message } from 'antd'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import LogLinkReport from './LogLinkReport'
import LogRichMenuReport from './LogRichMenuReport'
import LogUnsignReport from './LogUnsignReport'

const options = [
  {
    title: 'หมอ',
    value: 'doctor',
    key: 'doctor',
  },
  {
    title: 'เซลล์',
    value: 'salesman',
    key: 'salesman',
  },
  {
    title: 'ประวัติการนัด',
    value: 'meeting',
    key: 'meeting',
  },
]

const Report = () => {
  const [meeting, setMeeting] = useState(false)
  const [dataDownload, setDataDownload] = useState()
  const [date, setDate] = useState({})

  const handleChange = async (value) => {
    if (value === 'doctor' || value === 'salesman') {
      try {
        setMeeting(false)
        const result = await client.get(`/${value}`).then((res) => res.data.data)
        const mapData = result.map((v) => {
          if (value === 'doctor') {
            return {
              medicalId: v.medicalId,
              email: v.email,
              saleId: v.saleId,
              specialty: v.specialty,
              preference: v.preference,
              action_tag: v.preferences?.[0],
              tokenLine: v.tokenLine,
              createdAt: dayjs(v.createdAt).format('YYYY-MM-DD HH:mm'),
              updatedAt: dayjs(v.updatedAt).format('YYYY-MM-DD HH:mm'),
            }
          } else {
            return {
              saleId: v.saleId,
              preference: v.preference,
              tokenLine: v.tokenLine,
              createdAt: dayjs(v.createdAt).format('YYYY-MM-DD HH:mm'),
              updatedAt: dayjs(v.updatedAt).format('YYYY-MM-DD HH:mm'),
            }
          }
        })
        setDataDownload(mapData)
        message.success('Success')
      } catch (e) {
        message.error('Fail')
        setDataDownload(null)
        console.log(e)
      }
    } else if (value === 'meeting') {
      setMeeting(true)
      setDataDownload(null)
    } else {
      setMeeting(false)
      setDataDownload(null)
    }
  }

  const onDateChange = async (dates, dateStrings) => {
    setDate({
      startDate: dates[0],
      endDate: dates[1],
    })
    try {
      if (meeting) {
        const result = await client({
          method: 'GET',
          url: '/meeting',
          params: {
            where: {
              createdAt: {
                $gte: dayjs(dates[0]).startOf('date'),
                $lte: dayjs(dates[1]).endOf('date'),
              },
            },
            sort: { createdAt: -1 },
          },
        }).then((res) => res.data.data)
        message.success('Success')
        setDataDownload(result)
      }
    } catch (e) {
      message.error('Fail')
    }
  }
  return (
    <PageLayout title="ออกรายงาน">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Select onChange={handleChange} style={{ width: 200 }}>
          {options.map((item) => {
            return (
              <Select.Option key={item.key} value={item.value}>
                {item.title}
              </Select.Option>
            )
          })}
        </Select>

        {meeting && <DatePicker.RangePicker onChange={onDateChange} name="startDate" />}

        {dataDownload && (
          <CSVLink data={dataDownload} filename={'Report.csv'} className="ant-btn ant-btn-primary">
            Export
          </CSVLink>
        )}
      </div>
      <LogLinkReport />
      <LogRichMenuReport />
      <LogUnsignReport />
    </PageLayout>
  )
}

export default Report
