export function ExcelToJSON() {
  this.parseExcel = function (file) {
    return new Promise((resolve, reject) => {
      try {
        var reader = new FileReader()
        reader.onload = function (e) {
          var data = e.target.result
          // eslint-disable-next-line no-undef
          var workbook = XLSX.read(data, {
            type: 'binary',
          })
          workbook.SheetNames.forEach(function (sheetName, index) {
            // Here is your object
            if (index !== 0) {
              return true
            }
            // eslint-disable-next-line no-undef
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
            const data = XL_row_object
            resolve(data)
          })
        }
        reader.onerror = function (ex) {
          console.log(ex)
        }
        reader.readAsBinaryString(file)
      } catch (e) {
        reject(e)
      }
    })
  }
}
