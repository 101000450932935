import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

const Logout = () => {
  const history = useHistory()
  const { logout } = useAuth()

  useEffect(() => {
    logout().then(() => history.push('/'))
  })

  return <div>Logout</div>
}

export default Logout
