import ListOperation from '../../components/CRUD/ListOperation'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
export const columns = [
  {
    title: 'ชื่อ',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'นามสกุล',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'รหัสประจำตัว',
    dataIndex: 'saleId',
    key: 'saleId',
  },
  {
    title: 'ไลน์ล็อคอิน',
    render: function isLineActive(record) {
      return record.tokenLine ? (
        <CheckOutlined style={{ fontSize: 24, color: '#A9B31C' }} />
      ) : (
        <CloseOutlined style={{ fontSize: 24 }} />
      )
    },
  },
  {
    title: 'ไลน์ไอดี',
    render: function showLineID(record) {
      return <p>{record.tokenLine}</p>
    },
  },
  {
    title: 'รายชื่อหมอที่ดูแล',
    render: function Action(record) {
      return (
        <ListOperation
          isEdit={false}
          isDelete={false}
          row={record}
          basePath="/managesales"
          deletePath={`/salesman/${record._id}`}
        />
      )
    },
  },
]

export const doctorColumns = [
  {
    title: 'ชื่อ',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'นามสกุล',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'อีเมล์',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'รหัสประจำตัว',
    dataIndex: 'medicalId',
    key: 'medicalId',
  },
  {
    title: 'ไลน์ไอดี',
    render: function showLineID(record) {
      return <p>{record.tokenLine}</p>
    },
  },
]
export const meetingColumns = [
  {
    title: 'รหัสผู้แทน',
    dataIndex: 'saleId',
    key: 'saleId',
  },
  {
    title: 'หมอ',
    render: function doctorName(record) {
      return (
        <p>
          {record.doctorFirstname} {record.doctorLastname}
        </p>
      )
    },
  },
  {
    title: 'ชื่อไลน์',
    dataIndex: 'doctorLinename',
    key: 'doctorLinename',
  },
  {
    title: 'โทร',
    dataIndex: 'telNo',
    key: 'telNo',
  },
  {
    title: 'หัวข้อ',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'เวลานัด',
    dataIndex: 'meetingTime',
    key: 'meetingTime',
  },
  {
    title: 'วันที่กด',
    render: function showDate(record) {
      return <p>{dayjs(record.createdAt).format('YYYY-MM-DD')}</p>
    },
  },
]
