import { useState, useRef } from 'react'
import { Table } from 'antd'
import Fetch from '../CRUD/Fetch'
import PropTypes from 'prop-types'

const TableList = ({ fetch = () => {}, columns, key }) => {
  const defaultPage = 1
  const defaultPageSize = 10
  const fetchRef = useRef(null)
  const [page, setpage] = useState(defaultPage)
  const [pageSize, setpageSize] = useState(defaultPageSize)

  const handleChange = (pagination) => {
    setpage(pagination.current)
    setpageSize(pagination.pageSize)
  }

  return (
    <Fetch fetch={fetch} params={{ page, pageSize }} ref={fetchRef}>
      {({ data, total }) => (
        <Table
          rowKey={key}
          onChange={handleChange}
          dataSource={data}
          columns={columns}
          pagination={{
            defaultCurrent: defaultPageSize,
            defaultPageSize: defaultPageSize,
            current: page,
            pageSize,
            total,
          }}
        />
      )}
    </Fetch>
  )
}

TableList.propTypes = {
  key: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
}

export default TableList
