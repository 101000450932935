import { Typography } from 'antd'
import { useHistory, Redirect } from 'react-router-dom'
import * as yup from 'yup'
import { message, Image } from 'antd'
import Logo from '../../images/Sanofi-logo-01.png'

import { useAuth } from '../../contexts/AuthContext'
import { Form, TextInput, SubmitButton } from '../../components/Form'

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
})

const Login = () => {
  const { login, user } = useAuth()
  const history = useHistory()
  const handleSubmit = async (formData) => {
    try {
      await login(formData)
      message.success('Login successful')
      history.push('/dashboard')
    } catch (e) {
      message.error('Login failed')
    }
  }

  if (user) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      {/* <Typography.Title>Sign in</Typography.Title> */}
      <Image width={200} src={Logo} preview={false} />
      <Typography.Paragraph style={{ width: '50%', color: '#525CA3' }}>
        We, at Sanofi, are there beside people in need, as a health journey partner
      </Typography.Paragraph>
      <TextInput label="Email" formItemOptions={{ formItemLayout: null }} name="email" />
      <TextInput
        label="Password"
        formItemOptions={{ formItemLayout: null }}
        name="password"
        type="password"
      />
      <SubmitButton formItemOptions={{ formItemLayout: null }} label="" block type="primary">
        Login
      </SubmitButton>
      {/* <div style={{ textAlign: 'right' }}>
        <Link to="/forgotPassword">Forgot password</Link>
      </div> */}
    </Form>
  )
}

export default Login
