import PropTypes from 'prop-types'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { Layout, Menu, Image, Typography } from 'antd'
import { useAuth } from '../contexts/AuthContext'
import Logo from '../images/Sanofi-logo-01.png'

const { Sider, Content } = Layout

const AppLayout = ({ children }) => {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    return <Redirect to="/login" />
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider theme="light" style={{ boxShadow: '0px 3px 10px #00000029' }}>
        <div style={{ padding: 10 }}>
          <Image src={Logo} preview={false} style={{ paddingTop: 10 }} />
          <Typography.Paragraph style={{ textAlign: 'center', fontSize: 5, color: '#525CA3' }}>
            We, at Sanofi, are there beside people in need, as a health journey partner
          </Typography.Paragraph>
        </div>
        <Menu
          theme="light"
          defaultSelectedKeys={['/dashboard']}
          selectedKeys={[location.pathname]}
          mode="inline"
          className="customSelected"
        >
          <Menu.Item key="/dashboard">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/managedocters">
            <Link to="/managedocters">จัดการหมอ</Link>
          </Menu.Item>
          <Menu.Item key="/managesales">
            <Link to="/managesales">จัดการเซลล์</Link>
          </Menu.Item>
          <Menu.Item key="/meeting">
            <Link to="/meeting">ประวัติการนัด</Link>
          </Menu.Item>
          <Menu.Item key="/report">
            <Link to="/report">ออกรายงาน</Link>
          </Menu.Item>
          <Menu.Item key="/logout">
            <Link to="/logout">ออกจากระบบ</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ backgroundColor: 'white' }}>{children}</Content>
      </Layout>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default AppLayout
