import { Switch, Route, useRouteMatch } from 'react-router-dom'
import List from './List'
import View from './View'

const ManageSalesman = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <List />
      </Route>
      <Route exact path={`${path}/:id`}>
        <View />
      </Route>
    </Switch>
  )
}

export default ManageSalesman
