import { useState, useRef } from 'react'
import { Table, Button, Upload, Input } from 'antd'
import PageLayout from '../../layouts/PageLayout'
import Fetch from '../../components/CRUD/Fetch'
import { UploadOutlined } from '@ant-design/icons'
import { ExcelToJSON } from '../../utils/excel'
import { columns } from './columns'
import { client } from '../../utils/client'

const ManageDocters = () => {
  const defaultPage = 1
  const defaultPageSize = 10
  const fetchRef = useRef(null)
  const [page, setpage] = useState(defaultPage)
  const [pageSize, setpageSize] = useState(defaultPageSize)
  const [payload, setPayload] = useState()

  const fetch = async ({ page, pageSize }) => {
    const result = await client
      .get('/doctor', {
        params: {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          order: { from: 'ASC' },
        },
      })
      .then((res) => res.data)
    return result
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const xl2json = new ExcelToJSON()
      const data = await xl2json.parseExcel(file)
      const newData = data.map((item) => {
        return {
          email: item.email,
          firstname: item.firstname,
          lastname: item.lastname,
          mainHospitalName: item.mainHospitalName,
          medicalId: item.medicalId,
          saleId: item.saleId.split(','),
          specialty: item.specialty,
          telNo: item.telNo,
        }
      })
      const result = await client.post('/doctor', {
        data: newData,
      })
      setPayload(newData)
    } catch (err) {
      console.log('Error: ', err)
      const error = new Error('Some error')
      onError({ err })
    }
  }

  const handleChange = (pagination) => {
    setpage(pagination.current)
    setpageSize(pagination.pageSize)
  }

  // const search = (value) => {
  //   console.log(payload)
  // }

  return (
    <Fetch fetch={fetch} params={{ page, pageSize }} ref={fetchRef}>
      {({ data, total }) => (
        <PageLayout
          title="จัดการหมอ"
          extra={[
            <div key="extra" style={{ display: 'flex', gap: 15 }}>
              <Button
                type="link"
                href={`${
                  process.env.REACT_APP_API_BASE_URL
                }/users/preferences?accessToken=${localStorage.getItem('token')}`}
                download
              >
                Download Line ID
              </Button>
              <Upload accept=".xlsx" customRequest={customRequest} showUploadList={false}>
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  style={{ backgroundColor: '#B9A467', border: 'none' }}
                >
                  อัพโหลดไฟล์
                </Button>
              </Upload>
            </div>,
          ]}
        >
          {/* <Input.Search placeholder="Search by..." enterButton onSearch={search} /> */}
          <Table
            rowKey="medicalId"
            onChange={handleChange}
            dataSource={data}
            columns={columns}
            pagination={{
              defaultCurrent: defaultPageSize,
              defaultPageSize: defaultPageSize,
              current: page,
              pageSize,
              total,
            }}
          />
        </PageLayout>
      )}
    </Fetch>
  )
}

export default ManageDocters
