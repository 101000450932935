import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import { Skeleton } from 'antd'
import client from './utils/client'
import { AuthProvider } from './contexts/AuthContext'
import './App.less'

const App = () => {
  return (
    <Router>
      <AuthProvider
        forbiddenPath="/forbidden"
        client={client}
        loadingComponent={() => <Skeleton />}
      >
        <Routes />
      </AuthProvider>
    </Router>
  )
}

export default App
