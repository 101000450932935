import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Button, Popconfirm, message } from 'antd'
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { client } from '../../utils/client'

const ListOperation = ({
  row,
  basePath,
  isEdit = true,
  isDelete = true,
  isView = true,
  deletePath,
  handleReload,
}) => {
  const viewOnly = !isEdit && !isDelete
  const deleteOnly = !isEdit && !isView
  const onDelete = async () => {
    try {
      await client.delete(deletePath)
      message.success('ลบสำเร็จ')
      handleReload()
    } catch (e) {
      message.error('ล้มเหลว')
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={`${basePath}/${row._id}/edit`}>แก้ไข</Link>
      </Menu.Item>
      <Menu.Item>
        {/* <div to={`${basePath}/${row.id}/delete`}> */}
        <Popconfirm title="ยืนยันการลบ" okText="ยืนยัน" cancelText="ยกเลิก" onConfirm={onDelete}>
          <div>ลบ</div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  if (viewOnly) {
    return (
      <Button>
        <Link to={`${basePath}/${row._id}`}>ดู</Link>
      </Button>
    )
  }

  if (deleteOnly) {
    return (
      <Popconfirm title="ยืนยันการลบ" okText="ยืนยัน" cancelText="ยกเลิก" onConfirm={onDelete}>
        <Button type="primary" danger>
          ลบ
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
      <Link to={`${basePath}/${row._id}`}>ดู</Link>
    </Dropdown.Button>
  )
}

ListOperation.propTypes = {
  row: PropTypes.shape({
    _id: PropTypes.string,
  }),
  basePath: PropTypes.string,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  isView: PropTypes.bool,
  deletePath: PropTypes.string,
  handleReload: PropTypes.func,
}

export default ListOperation
