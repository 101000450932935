import React from 'react'
import { Typography, Image } from 'antd'
import Doctors from '../../images/Doctors.jpg'
import Sales from '../../images/Sales.jpg'
import PropTypes from 'prop-types'

const Card = ({ type = 'doctor', countPerson = 0 }) => {
  return (
    <div
      style={{
        width: 200,
        height: 'auto',
        backgroundColor: '#525ca3',
        position: 'relative',
        marginRight: 40,
      }}
    >
      <Image src={type === 'doctor' ? Doctors : Sales} preview={false} />
      <div
        style={{
          backgroundColor: 'white',
          width: 150,
          borderRadius: 50,
          padding: '3px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '35%',
          left: '12%',
        }}
      >
        <Typography.Title level={5} style={{ color: '#525CA3', margin: '0px' }}>
          {type === 'doctor' ? 'จำนวณหมอทั้งหมด' : 'จำนวณเซลล์ทั้งหมด'}
        </Typography.Title>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px 0 ',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Title style={{ margin: 0, fontSize: 64, color: '#fff' }}>
            {countPerson}
          </Typography.Title>
          <Typography.Paragraph strong style={{ color: '#fff' }} level={6}>
            คน
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  type: PropTypes.string.isRequired,
  countPerson: PropTypes.number.isRequired,
}

export default Card
