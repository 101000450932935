import { useRef } from 'react'
import PageLayout from '../../layouts/PageLayout'
import { client } from '../../utils/client'
import TableList from '../../components/TableList'
import { columns } from './columns'

const Meeting = () => {
  const fetch = async () => {
    const result = await client
      .get('meeting', {
        params: {
          sort: { createdAt: -1 },
        },
      })
      .then((res) => res.data)
    return result
  }
  return (
    <PageLayout title="ประวัติการนัด">
      <TableList key="_id" fetch={fetch} columns={columns}></TableList>
    </PageLayout>
  )
}

export default Meeting
