import { useRef } from 'react'
import Fetch from '../../components/CRUD/Fetch'
import PageLayout from '../../layouts/PageLayout'
import { useParams } from 'react-router-dom'
import { client } from '../../utils/client'
import { Row, Col, Typography, Tabs } from 'antd'
import TableList from '../../components/TableList'
import { doctorColumns, meetingColumns } from './columns'

const View = () => {
  const fetchRef = useRef(null)
  const params = useParams()
  const { TabPane } = Tabs

  const fetch = async () => {
    const result = await client.get(`/salesman/${params.id}`).then((res) => res.data)
    return result
  }

  const getDoctorList = async () => {
    const result = await client.get(`/salesman/${params.id}/doctor`).then((res) => res.data)
    return result
  }

  const getMeetingList = async () => {
    const result = await client.get(`/salesman/${params.id}/meeting`).then((res) => res.data)
    return result
  }

  return (
    <Fetch fetch={fetch} ref={fetchRef}>
      {({ firstname, lastname, saleId, managerFirstname, managerLastname }) => {
        return (
          <PageLayout title="ข้อมูลประจำตัว" shownBack>
            <Row>
              <Col span={5}>
                <Typography.Title level={2}>
                  {firstname} {lastname}
                </Typography.Title>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography.Paragraph className="gray">เซลล์ไอดี:</Typography.Paragraph>
                  <Typography.Paragraph strong>{saleId}</Typography.Paragraph>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography.Paragraph className="gray">หัวหน้า:</Typography.Paragraph>
                  <Typography.Paragraph strong>
                    {managerFirstname} {managerLastname}
                  </Typography.Paragraph>
                </div>
              </Col>
            </Row>
            <Tabs defaultActiveKey="1">
              <TabPane tab="จัดการหมอ" key="1">
                <TableList
                  key="medicalId"
                  fetch={getDoctorList}
                  columns={doctorColumns}
                ></TableList>
              </TabPane>
              <TabPane tab="ประวัติการนัด" key="2">
                <TableList key="_id" fetch={getMeetingList} columns={meetingColumns}></TableList>
              </TabPane>
            </Tabs>
          </PageLayout>
        )
      }}
    </Fetch>
  )
}

export default View
