import { PageHeader, Avatar, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

function PageLayout({ children, title, extra, profile = {}, shownBack = false }) {
  const { name, pic } = profile
  const history = useHistory()
  return (
    <div style={{ padding: '16px 24px' }}>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Typography.Title level={3} style={{ margin: '0 10px', color: '#4A4A4A' }}>
          Hi, {name || 'Guest'}
        </Typography.Title>
        <Avatar size={64} src={pic || 'https://avatar.proxied.cloud/1'} />
      </div> */}
      <PageHeader
        title={title}
        extra={extra}
        onBack={() => history.goBack()}
        backIcon={shownBack ? <ArrowLeftOutlined /> : false}
      >
        {children}
      </PageHeader>
    </div>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.array,
  profile: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  shownBack: PropTypes.bool,
}

export default PageLayout
