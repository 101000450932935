import ListOperation from '../../components/CRUD/ListOperation'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
export const columns = [
  {
    title: 'ชื่อ',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'นามสกุล',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'อีเมล์',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'รหัสประจำตัว',
    dataIndex: 'medicalId',
    key: 'medicalId',
  },
  {
    title: 'เซลล์ผู้ดูแล',
    render: function showSale(record) {
      return record.saleId.map((v) => {
        return <p key={v}>{v},</p>
      })
    },
  },
  {
    title: 'ไลน์ล็อคอิน',
    render: function isLineActive(record) {
      return record.tokenLine ? (
        <CheckOutlined style={{ fontSize: 24, color: '#A9B31C' }} />
      ) : (
        <CloseOutlined style={{ fontSize: 24 }} />
      )
    },
  },
  {
    title: 'ไลน์ไอดี',
    render: function showLineID(record) {
      return <p>{record.tokenLine}</p>
    },
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'telNo',
    key: 'telNo',
  },
  // {
  //   title: 'จัดการ',
  //   render: function Action(record) {
  //     return <ListOperation row={record} basePath="/doctor" deletePath={`/doctor/${record._id}`} />
  //   },
  // },
]
