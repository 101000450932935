import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import AppLayout from '../layouts/AppLayout'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import Dashboard from '../pages/Dashboard'
import ManageDocters from '../pages/ManageDoctors'
import ManageSalesman from '../pages/ManageSalesman'
import Report from '../pages/Report'
import Meeting from '../pages/Meeting'
const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path={['/login']}>
        <AuthLayout>
          <Route exact path="/login">
            <Login />
          </Route>
        </AuthLayout>
      </Route>
      <Route
        path={['/dashboard', '/logout', '/managedocters', '/managesales', '/meeting', '/report']}
      >
        <AppLayout>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/managedocters">
            <ManageDocters />
          </Route>
          <Route exact path="/meeting">
            <Meeting />
          </Route>
          <Route path="/managesales">
            <ManageSalesman />
          </Route>
          <Route path="/report">
            <Report />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
        </AppLayout>
      </Route>
    </Switch>
  )
}

export default Routes
