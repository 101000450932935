import { useState } from 'react'
import { Typography, DatePicker, Input, Button } from 'antd'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { client } from '../../utils/client'

const { RangePicker } = DatePicker

const { Title } = Typography

const LogUnsignReport = () => {
  const [period, setPeriod] = useState([moment().startOf('month'), moment().endOf('month')])

  const onSubmit = async () => {
    const data = await client
      .get('/logs/unsign', {
        params: {
          start: period?.[0]?.toISOString(),
          end: period?.[1]?.toISOString(),
        },
        responseType: 'blob',
      })
      .then((res) => res.data)

    if (data) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(data)
      link.href = url
      link.download = 'log-unsign.csv'
      link.click()

      URL.revokeObjectURL(url)
    }
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Title level={5}>รายงานบันทึกการเข้าใช้งานใหม่ของผู้ใช้</Title>
      <RangePicker
        defaultValue={[moment().startOf('month'), moment().endOf('month')]}
        format={'DD/MM/YYYY'}
        onChange={(value) => {
          setPeriod(value)
        }}
      />
      <div style={{ marginBottom: 20 }} />
      <Button type="primary" onClick={onSubmit}>
        ออกรายงาน
      </Button>
    </div>
  )
}

export default LogUnsignReport
