import { useRef } from 'react'
import Fetch from '../../components/CRUD/Fetch'
import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/Card'
import { client } from '../../utils/client'

const Dashboard = () => {
  const fetchRef = useRef(null)
  const fetch = async () => {
    const saleman = await client.get('/salesman').then((res) => res.data)
    const doctor = await client.get('/doctor').then((res) => res.data)
    return { saleman, doctor }
  }
  return (
    <Fetch fetch={fetch} retf={fetchRef}>
      {({ doctor: { total: doctorTotal }, saleman: { total: salesmanTotal } }) => (
        <PageLayout title="รายการสมาชิก">
          <div style={{ display: 'flex' }}>
            <Card type="doctor" countPerson={doctorTotal}></Card>
            <Card type="sales" countPerson={salesmanTotal}></Card>
          </div>
        </PageLayout>
      )}
    </Fetch>
  )
}

export default Dashboard
